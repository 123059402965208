import { FilterDataBackendData } from "../../../redux/features/carSlice";
import { FilterDataObj } from "../../../redux/features/carStateSlice";

export const clearBackendData: FilterDataBackendData = {
  page: 1,
  lowPrice: 0,
  highPrice: 1750000,
  lowMileage: 0,
  highMileage: 300000,
  sortByState: "All",
  makeAndModalStates: {
    Acura: false,
    AlfaRomeo: false,
    Audi: false,
    BMW: false,
    Buick: false,
    Cadillac: false,
    Chevrolet: false,
    Chrysler: false,
    Dodge: false,
    FIAT: false,
    Ford: false,
    Genesis: false,
    GMC: false,
    Honda: false,
    Hyundai: false,
    INFINITI: false,
    Jaguar: false,
    Jeep: false,
    Kia: false,
    LandRover: false,
    Lexus: false,
    Lincoln: false,
    Lucid: false,
    Maserati: false,
    Mazada: false,
    MercedesBenz: false,
    MINI: false,
    Mitsubishi: false,
    Nissan: false,
    Polestar: false,
    Porsche: false,
    Ram: false,
    Rivian: false,
    Scion: false,
    Subaru: false,
    Tesla: false,
    Toyota: false,
    Volkswagen: false,
    Volvo: false,
  }
};

export const clearReduxState: FilterDataObj = {
  lowPrice: 0,
  highPrice: 1750000,
  lowMileage: 0,
  highMileage: 300000,
  sortByState: "All",
  makeAndModalStates: {
    Acura: false,
    AlfaRomeo: false,
    Audi: false,
    BMW: false,
    Buick: false,
    Cadillac: false,
    Chevrolet: false,
    Chrysler: false,
    Dodge: false,
    FIAT: false,
    Ford: false,
    Genesis: false,
    GMC: false,
    Honda: false,
    Hyundai: false,
    INFINITI: false,
    Jaguar: false,
    Jeep: false,
    Kia: false,
    LandRover: false,
    Lexus: false,
    Lincoln: false,
    Lucid: false,
    Maserati: false,
    Mazada: false,
    MercedesBenz: false,
    MINI: false,
    Mitsubishi: false,
    Nissan: false,
    Polestar: false,
    Porsche: false,
    Ram: false,
    Rivian: false,
    Scion: false,
    Subaru: false,
    Tesla: false,
    Toyota: false,
    Volkswagen: false,
    Volvo: false,
  }
};
