import * as React from 'react';
import LoanCalculator from './LoanCalculator/Loan';

export interface IPracProps {
}

export default function Prac (props: IPracProps) {
  return (
    <div className='w-full h-auto'>



<LoanCalculator />

    </div>
  );
}
