import { Modal } from "@mui/material";
import * as React from "react";
import { motion } from "framer-motion";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import { Dispatch, UseSelector } from "../../redux/store";
import { setAnyTypeOfModal } from "../../redux/features/applicationSlice";
import { determineLengthError } from "../dashboardComponents/utils/determineLengthError";
import { RetirementGoals } from "../../redux/features/modalSlices/retirementSlice";
import { CarObjWithFormattedData } from "../../redux/features/modalSlices/carModalSlice";
import { HouseObjWithFormattedData } from "../../redux/features/modalSlices/houseSlice";

export interface IFirstModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function FirstModal({ open, setOpen }: IFirstModalProps) {
  const dispatch = Dispatch();
  const { carGoals } = UseSelector((state) => state.carModalSlice);
  const { houseGoals } = UseSelector((state) => state.houseSlice);
  const { retireGoals } = UseSelector((state) => state.retireSlice);

  const concatData: Array<RetirementGoals | CarObjWithFormattedData | HouseObjWithFormattedData> = [...retireGoals, ...carGoals, ...houseGoals];

  function handleOpenModalCheckForLengthError() {
    return determineLengthError(concatData);
  }
  return (
    <Modal onClose={() => setOpen(false)} open={open}>
      <div className=" absolute top-[50%] left-[50%] transfrom -translate-x-[50%] -translate-y-[50%]  dark:bg-homeBg bg-lightHomeBg lg:w-[30%] md:w-[40%] sm:w-[45%] 2xl:w-[25%] w-[95%] rounded-lg">
        {/* Content */}
        <motion.div
          className="w-full h-full  flex flex-col p-4 dark:text-darkText text-lightText"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.5,
            delay: 0.3,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          {/* First Box */}
          <div className="w-full justify-between flex items-center">
            <h1 className=" text-[22px] font-medium">Select Your Goal</h1>
            <CloseOutlinedIcon onClick={() => setOpen(false)} className=" cursor-pointer" />
          </div>

          <hr className="my-2 border dark:border-darkText border-lightText" />

          {/* Second Box */}
          <div className="w-auto flex flex-col h-auto">
            {/* Retirement */}
            <div className="w-auto flex items-center my-3 cursor-pointer">
              <h1
                className="underline text-[17px] font-medium"
                onClick={() => {
                  setOpen(false);
                  dispatch(setAnyTypeOfModal({ value: true, type: "Retirement" }));
                }}
              >
                Retirement
              </h1>
              <NavigateNextOutlinedIcon
                className="text-[19px]"
                onClick={() => {
                  setOpen(false);
                  dispatch(setAnyTypeOfModal({ value: true, type: "Retirement" }));
                }}
              />
            </div>

            {/* House */}
            <div className="w-auto flex items-center my-3 cursor-pointer">
              <h1
                className="underline text-[17px] font-medium"
                onClick={() => {
                  setOpen(false);
                  if (handleOpenModalCheckForLengthError()) {
                    dispatch(setAnyTypeOfModal({ value: true, type: "House" }));
                  }
                }}
              >
                House
              </h1>
              <NavigateNextOutlinedIcon
                className="text-[19px]"
                onClick={() => {
                  setOpen(false);
                  if (handleOpenModalCheckForLengthError()) {
                    dispatch(setAnyTypeOfModal({ value: true, type: "House" }));
                  }
                }}
              />
            </div>

            {/* Car */}
            <div className="w-auto flex items-center my-3 cursor-pointer">
              <h1
                className="underline text-[17px] font-medium"
                onClick={() => {
                  setOpen(false);
                  if (handleOpenModalCheckForLengthError()) {
                    dispatch(setAnyTypeOfModal({ value: true, type: "Car" }));
                  }
                }}
              >
                Car
              </h1>
              <NavigateNextOutlinedIcon
                className="text-[19px]"
                onClick={() => {
                  setOpen(false);
                  if (handleOpenModalCheckForLengthError()) {
                    dispatch(setAnyTypeOfModal({ value: true, type: "Car" }));
                  }
                }}
              />
            </div>

            <div className="w-full h-auto flex flex-col mt-3">
            <h1 className=" text-[17px] font-medium text-chartGreen">News!</h1>
                <p className="text-[12px]">New calculators and functions will be added soon ... Investing, Cost of Debt, and many more stay tuned :) </p>
            </div>

            {/* <div className="w-auto flex items-center my-3 cursor-pointer">
              <h1 className="underline text-[17px] font-medium">Other</h1>
              <NavigateNextOutlinedIcon className="text-[19px]" />
            </div> */}
          </div>
        </motion.div>
      </div>
    </Modal>
  );
}
