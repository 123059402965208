import { FilterDataBackendData } from "../../../redux/features/carSlice";
import { FilterDataObj } from "../../../redux/features/carStateSlice";
import { FormFields } from "../hooks/useFormHook";

// This function is just responilbe for formatting the data in a certain way for the backend and for cleaning up carvana input page
export function updateSearch(input: FormFields,currentPage:number) {
    const {
      lowPrice,
      highPrice,
      lowMileage,
      highMileage,
      sortByState,
      Acura,
      AlfaRomeo,
      Audi,
      BMW,
      Buick,
      Cadillac,
      Chevrolet,
      Chrysler,
      Dodge,
      FIAT,
      Ford,
      Genesis,
      GMC,
      Honda,
      Hyundai,
      INFINITI,
      Jaguar,
      Jeep,
      Kia,
      LandRover,
      Lexus,
      Lincoln,
      Lucid,
      Maserati,
      Mazada,
      MercedesBenz,
      MINI,
      Mitsubishi,
      Nissan,
      Polestar,
      Porsche,
      Ram,
      Rivian,
      Scion,
      Subaru,
      Tesla,
      Toyota,
      Volkswagen,
      Volvo,
    } = input;

    const newObj: FilterDataBackendData = {
    page:currentPage,
      lowMileage: parseFloat(lowMileage.replace(/[,%$]/gm, "")),
      highMileage: parseFloat(highMileage.replace(/[,%$]/gm, "")),
      lowPrice: parseFloat(lowPrice.replace(/[,%$]/gm, "")),
      highPrice: parseFloat(highPrice.replace(/[,%$]/gm, "")),
      sortByState,
      makeAndModalStates: {
        Acura,
        AlfaRomeo,
        Audi,
        BMW,
        Buick,
        Cadillac,
        Chevrolet,
        Chrysler,
        Dodge,
        FIAT,
        Ford,
        Genesis,
        GMC,
        Honda,
        Hyundai,
        INFINITI,
        Jaguar,
        Jeep,
        Kia,
        LandRover,
        Lexus,
        Lincoln,
        Lucid,
        Maserati,
        Mazada,
        MercedesBenz,
        MINI,
        Mitsubishi,
        Nissan,
        Polestar,
        Porsche,
        Ram,
        Rivian,
        Scion,
        Subaru,
        Tesla,
        Toyota,
        Volkswagen,
        Volvo,
      },
    };

    const objStates:FilterDataObj  = {
          lowMileage: parseFloat(lowMileage.replace(/[,%$]/gm, "")),
          highMileage: parseFloat(highMileage.replace(/[,%$]/gm, "")),
          lowPrice: parseFloat(lowPrice.replace(/[,%$]/gm, "")),
          highPrice: parseFloat(highPrice.replace(/[,%$]/gm, "")),
          sortByState,
          makeAndModalStates: {
            Acura,
            AlfaRomeo,
            Audi,
            BMW,
            Buick,
            Cadillac,
            Chevrolet,
            Chrysler,
            Dodge,
            FIAT,
            Ford,
            Genesis,
            GMC,
            Honda,
            Hyundai,
            INFINITI,
            Jaguar,
            Jeep,
            Kia,
            LandRover,
            Lexus,
            Lincoln,
            Lucid,
            Maserati,
            Mazada,
            MercedesBenz,
            MINI,
            Mitsubishi,
            Nissan,
            Polestar,
            Porsche,
            Ram,
            Rivian,
            Scion,
            Subaru,
            Tesla,
            Toyota,
            Volkswagen,
            Volvo,
          },
        };

    return {
        backendData:newObj,
        reduxData:objStates
    }
  }